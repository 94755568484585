import { styled } from '@mui/material';

export const CardWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '7px 16px',
  marginBottom: 8,
  backgroundColor: '#7898FF',
  borderRadius: 2,
  color: '#FFFFFF',
  fontWeight: 'bold',
  cursor: 'move',
  span: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
