import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingTop: 14,
    gap: 24,
    minHeight: 598,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelButton: {
    color: '#212121',
    marginRight: 20,
    border: 'none',
    outline: 'none',
  },
  saveButton: {
    background: '#7898FF',
    color: 'white',
    border: '1px solid #7898FF',
    outline: 'none',
    '&:hover': {
      color: '#7898FF',
    },
  },
  addModel: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '20px 0',
  },
  iconPlus: {
    '& .MuiSvgIcon-root': {
      fill: '#7898FF',
    },
  },
  modelsWrapper: {
    height: 124,
    display: 'flex',
    alignItems: 'start',
    alignContent: 'start',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
    overflowY: 'auto',
    margin: '16px 0',
    background: '#F7F8FB',
    color: '#B8B9BF',
    padding: '6px 12px',
  },
  fieldTitle: {
    color: '#252733',
    fontFamily: 'Proxima Nova',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    textTransform: 'uppercase',
    marginBottom: 8,
  },
  dropdown: {},
  configuredLayout: {},
  inputWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  addmakesBtn: {
    width: 114,
    background: '#7898FF',
    color: 'white',
    border: '1px solid #7898FF',
    outline: 'none',
    alignSelf: 'flex-end',
    '&:hover': {
      color: '#7898FF',
    },
    '&.Mui-disabled': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DADADA',
      color: '#DADADA',
    },
  },
  firstColumnLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    flex: 1,
    minHeight: 598,
  },
  attentionWrapper: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    width: '100%',
    padding: '12px 16px',
    alignItems: 'center',
    gap: 8,
    background: '#F7F8FB',
    color: '#252733',

    /* Form placeholder filled */
    fontFamily: 'Proxima Nova',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));
